import React from 'react'
import './index.scss'

const Footer = () => {
    return (
        <footer className="footer">
          <ul className="flex center footer-list">
            <li className="footer-list-item light">
              <a href="http://www.warnermusic.fr/Mentions-legales" target="_blank" rel="noreferrer">Mentions Légales</a>
            </li>
            <li className="footer-list-item light">
              <a href="https://www.wminewmedia.com/terms-of-use/fr/" target="_blank" rel="noreferrer">CGU</a>
            </li>
            <li className="footer-list-item light">
              <a href="https://www.wminewmedia.com/cookies-policy/fr/" target="_blank" rel="noreferrer">Cookies</a>
            </li>
            <li className="footer-list-item light">
              <a className="ot-sdk-show-settings" style={{ cursor:"pointer" }}>Paramétrer mes cookies</a>
            </li>
          </ul>
            <li className="footer-list-item light">
              <p>© 2022 Warner Music France / Rec. 118 / 118LAB</p>
            </li>
        </footer>
    )
}

export default Footer