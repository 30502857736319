import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { distance, booleanPointInPolygon, point, polygon } from '@turf/turf'
import bipSound from '../../assets/sound/bip.wav'
import Bip1 from '../../assets/sound/ZONES_TAP_RECHERCHE_1_LOW.mp3'
import Bip2 from '../../assets/sound/ZONES_TAP_RECHERCHE_2_MEDIUM.mp3'
import Bip3 from '../../assets/sound/ZONES_TAP_RECHERCHE_3_HIGH.mp3'
import BipGood from '../../assets/sound/ZONES_SPOT_LOCALISE.mp3'
import './index.scss'
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import { createGeoJSONCircle } from '../../tools';
import IndicationPosition from './IndicationPosition';
import boucleProdAudio from '../../assets/sound/BOUCLE.mp3'
import boucleCity from '../../assets/sound/MAP_AMBIANCE_VILLE.mp3'
import CompteARebourd10SEC from '../../assets/sound/COMPTE_A_REBOURD_10SEC.mp3'
import {Howl} from 'howler';
import Boussole from '../CheckPlanques/boussole';
import Help from '../../components/Help';
import ReactHowler from 'react-howler/lib/ReactHowler';
import useSound from 'use-sound';
mapboxgl.accessToken = 'pk.eyJ1Ijoid2FybmVybXVzaWNmcmFuY2UiLCJhIjoiY2p0aWplZXd3MTRwZjQwbGhuNG12Mm0ybSJ9.LrneL3ZdUPgxBcBrg9FhQQ';

const FindComissariat = ({ currentStep, boucleProd, setCurrentStep, launchNotification }) => {
    const mapContainer = useRef(null);
    const positionCursorRef = useRef(null)

    const map = useRef(null);
    const [lng, setLng] = useState(2.53);
    const [lat, setLat] = useState(48.94);
    const [zoom, setZoom] = useState(12);
    const  [positionCursor, setPositionCursor] = useState(7)
    const [helpSMSSended, setHelpSMSSended] = useState(false)
    const [showBoussole, setShowBoussole] = useState(true)
    const [objectives, setObjectives] = useState([
        {
            lat: 2.3002495,
            long: 48.88443,
            isValid: false,
            zone: "polygon3",
            active: true,
            name: "Zino",
            nextIndex: 1,
            SMS: "Il est pas là, va plutôt chercher vers le Quick de Sevran",
            SMSAuthor: "LIBANAIS",
            excedantLat: 0.003,
            excedantLng: -0.001
        },
        {
            lat: 2.529745,
            long: 48.9505342,
            isValid: false,
            zone: "polygon2",
            active: true,
            name: "QuickSevran",
            SMS: " DA m'a passé ton num c’est maître zadourian, le gars que tu cherches vient de passer",
            nextIndex: 2,
            SMSAuthor: "INCONNU",
            excedantLat: -0.002,
            excedantLng: -0.003
        },
        {
            lat: 2.3197073,
            long: 48.8845177,
            isValid: true,
            zone: "polygon",
            active: true,
            name: "Avocate",
            nextIndex: 3,
            SMS: "",
            excedantLat: 0.002,
            excedantLng: 0.002
        }
    ])
    const [showIndication, setShowIndication] = useState(false)
    const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
    const bounds = [
        [2.25, 48.82],
        [2.70, 48.99]
    ]
    const [timeLeft, setTimeLeft] = useState(120);
    useEffect(() => {
        if (timeLeft === 0) {
            setCurrentStep(9)
        }

      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(timer);
    }, [timeLeft]);

    useEffect(() => {
        boucleProd.current.backMap.play()

        setTimeout(() => {
            launchNotification('LIBANAIS', "J'ai mis le GPS vers la premiere zone où il pourrait être, suis le")
            // launchNotification('INCONNU', 'Suis la boussole pour te déplacer sur la carte et trouver les zones.')
        }, 500);

        window.digitalData={							
            settings:{						
                reportSuites:"wmg,wmgfr"					
            },						
            page:{						
                pageInfo:{					
                    pageName:"DA Uzi:Le chemin des braves Game and Contest:Landing:Commissariat",				
                    server:"DA Uzi:Site",				
                    platform:"MIS Custom Page",				
                    devTeam:"Warner Music France"				 
                },					
                category:{					
                    primaryCategory:"DA Uzi:Landing Page",				
                    pageType:"Landing Page:Contest"				
                }					
            },						
            content:{						
                artist:"DA Uzi",					
                label:"Warner Music International",					
                sublabel:"Warner Music France"					
            }						
        }							
        document.dispatchEvent(new CustomEvent('SPAPageTriggered'));		
    }, [])

    useEffect(() => {
        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/warnermusicfrance/ckzxybn3v002w14qjfc40a6xa?optimize=true',
            center: [lng, lat],
            zoom: zoom,
            maxBounds: bounds,
            minZoom: 12
        });

        map.current.doubleClickZoom.disable();
        map.current.dragRotate.disable();
        map.current.touchZoomRotate.disableRotation();
    });

    useEffect(() => {
        if (map.current && helpSMSSended === false) {
            if (map.current.getBounds().contains([2.3002495, 48.88443])) {
                setHelpSMSSended(true)
                launchNotification('LIBANAIS', "zoom sur la zone et clique bien partout dedans, le gars y est surement caché et il sait qu'on le cherche")
            }
        }

        const currentZoneIndex = objectives.findIndex(objective => objective.active === true)
        
        if (map.current && currentZoneIndex === 0) {
            if (map.current.getBounds().contains([2.3002495, 48.88443])) {
                setShowBoussole(false)
                setShowIndication(true)
            } else {
                setShowBoussole(true)
                setShowIndication(false)
            }
        } else if (map.current && currentZoneIndex === 1) {
            if (map.current.getBounds().contains([2.529745, 48.9505342])) {
                setShowBoussole(false)
                setShowIndication(true)
            } else {
                setShowBoussole(true)
                setShowIndication(false)
            }
        } else if (map.current && currentZoneIndex === 2) {
            if (map.current.getBounds().contains([2.3197073, 48.8845177])) {
                setShowBoussole(false)
                setShowIndication(true)
            } else {
                setShowBoussole(true)
                setShowIndication(false)
            }
        }
    })


    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        })
    }, [])

    const setNewZone = (index, getNew) => {
        map.current.addSource(objectives[index].zone, createGeoJSONCircle([objectives[index].lat + objectives[index].excedantLat, objectives[index].long + objectives[index].excedantLng], 0.5));
        map.current.addSource(objectives[index].zone + "_1", createGeoJSONCircle([objectives[index].lat, objectives[index].long], 0.1));

        map.current.addLayer({
            "id": objectives[index].zone,
            "type": "fill",
            "source": objectives[index].zone,
            "layout": {},
            "paint": {
                "fill-color": "#FBDD3F",
                "fill-opacity": 0.4
            }
        });
        
        map.current.addLayer({
            "id": objectives[index].zone + "_1",
            "type": "line",
            "source": objectives[index].zone,
            "layout": {},
            "paint": {
                "line-color": "#FBDD3F",
                "line-opacity": 1,
                "line-width": 3
            }
        });
    }

    useEffect(() => {
        if (showIndication)
            positionCursorRef.current.style.left = positionCursor + "%"
    }, [positionCursor])

    useEffect(() => {
        map.current.on('load', () => {
            if (isMobile) {
                map.current.on('touchstart', checkObjectives);
            } else {
                map.current.on('click', checkObjectives);
            }

            setNewZone(0, false)
        })
    }, [map.current])

    const checkObjectives = (e) => {
        const testZones = []
        const currentEl = objectives.find(element => element.active === true)

            var to = [currentEl.lat, currentEl.long]
            var from = [e.lngLat.wrap().lng, e.lngLat.wrap().lat]
            var options = { units: 'meters' };

            if (currentEl.active) {
                if (booleanPointInPolygon(
                    point(from),
                    polygon([map.current.getSource(currentEl.zone)._data.features[0].geometry.coordinates[0]])
                )) {
                    testZones.push(true)
                    
                    var distanceText = distance(to, from, options);

                    if (distanceText < 900) {
                        setPositionCursor(Math.max(Math.min(128 - (distanceText/500*128) - 5, 85), 7))
                    } else {
                        setPositionCursor(7)
                    }

                    if (distanceText < 100) {
                        // sounds.bipGood.play()
                        if (currentEl.isValid) {
                            setTimeout(() => {
                                goodFind()
                            }, 500)
                        } else {
                            badFind()

                            setPositionCursor(Math.max(Math.min(128 - (distanceText/500*128) - 5, 90), 7))

                            setTimeout(() => {
                                setPositionCursor(7)
                            }, 2000)

                            map.current.setPaintProperty(currentEl.zone + "_1", 'line-opacity', 0);
                            map.current.setPaintProperty(currentEl.zone, 'fill-color', "rgba(255, 255, 255, 0.3)");
                            
                            setNewZone(currentEl.nextIndex, true)

                            currentEl.active = false
                            let newArr = [...objectives]; // copying the old datas array
                            currentEl.active = false; // replace e.target.value with whatever you want to change it to

                            setObjectives(newArr);

                            setTimeout(() => {
                                setShowIndication(false)
                            }, 2000)
                        }
                    }
                } else {
                    testZones.push(false)
                }
            }
        // if (!testZones.includes(true)) {
        //     setPositionCursor(7)
        // }
    }

    const goodFind = () => {
        setCurrentStep(2)
    }

    const badFind = () => {
        launchNotification( objectives.find(element => element.active === true).SMSAuthor, objectives.find(element => element.active === true).SMS, 5000)
    }

    return (
        <div className="findCommissariatPage">
            
            <ReactHowler
                src={boucleProdAudio}
                playing={true}
                loop={true}
                html5={true}
                volume={1}
            />
            <ReactHowler
                src={boucleCity}
                playing={true}
                loop={true}
                html5={true}
                volume={1}
            />
            <ReactHowler
                src={CompteARebourd10SEC}
                playing={timeLeft < 10 ? true : false}
                loop={true}
                html5={true}
                volume={1}
            />
            <Help text={"Suis la flèche pour trouver les zones jaunes. Une fois une zone trouvée, clique plusieurs fois dans les zones jaunes pour trouver le lieu exact"} />
            <p className="timeLeft">{("0" + Math.floor((timeLeft / 60))).slice(-2)}:{("0" + timeLeft%60).slice(-2)}</p>
            {showBoussole && <Boussole map={map} mapLng={lng} mapLat={lat} toFindLat={objectives.find(element => element.active === true).long} toFindLng={objectives.find(element => element.active === true).lat} />}
            {showIndication && <IndicationPosition positionCursorRef={positionCursorRef} />}
            <div ref={mapContainer} className="map-container"></div>
            <Footer />
        </div>
    );
}

export default FindComissariat