import React, { useState, useEffect, useRef } from "react"
import './App.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import FindComissariat from './pages/FindComissariat'
import FindIndic from './pages/FindIndic'
import TransitionScreen from './pages/TransitionScreen'
import Notification from './components/Notification'
import { BrowserRouter as Router } from "react-router-dom";
import Call from './pages/Call';
import CompteARebourd10SEC from './assets/sound/COMPTE_A_REBOURD_10SEC.mp3'
import BackMap from './assets/sound/MAP_RETOUR_MAP_APRES_CALL.mp3'
import CheckPlanques from "./pages/CheckPlanques";
import FacetimeCall from "./pages/FacetimeCall";
import StartingScreen from './pages/StartingScreen'
import Win from "./pages/Win";
import IndicationAudio from './assets/sound/GENERAL_CLIQUE_GENERAL_BOUTONS_TEMOINS_PLANQUES.mp3'
import BoucleLowPass from './assets/sound/BOUCLE_LOW_PASS.mp3'
import NameScreen from "./pages/NameScreen";
import { Howl, Howler } from 'howler'
import Introduction from "./pages/Introduction";
import Loose from "./pages/Loose";
import "./homepage.scss"
import Footer from "./components/Footer";

function App() {
  const notificationRef = useRef(null)
  const videoFacetimeRef  = useRef(null)
  const introductionRef = useRef(null)
  
  const soundScreen = useRef(null)
  const logoScreen = useRef(null)

  const [lastScreen, setLastScreen] = useState(-2)
  const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))

  const boucleProd = useRef({
    backMap: new Howl({
      src: [BackMap],
      html5: true,
      volume: 1,
      preload: false,
    }),
    IndicationAudio: new Howl({
      src: IndicationAudio,
      html5: true,
      volume: 1
    })
  })
 
  const [userName, setUserName] = useState('')

  const [currentStep, setCurrentStep] = useState(window.location.hash === "" ? -2 : parseInt(window.location.hash.substring(1)))
  const [notificationText, setNotificationText] = useState("")
  const [notificationAuthor, setNotificationAuthor] = useState("LIBANAIS")
  const [isOpenFacetime, setIsOpenFacetime] = useState(false)

  let timeNotification = null

  const launchNotification = (author, text, duration) => {
    clearTimeout(timeNotification)

    setNotificationText(text)
    setNotificationAuthor(author)
    
    notificationRef.current.classList.add('on')

    timeNotification = setTimeout(() => {
      notificationRef.current.classList.remove('on')
    }, duration ? duration : 6000);
  }

  useEffect(() => {
    if (currentStep === -2) {
      setTimeout(() => {
        introductionRef.current.classList.add('off')
      }, 4000)
  
      setTimeout(() => {
        setCurrentStep(-1)
      }, 6000)
    }
  }, [])

  useEffect(() => {
    window.scrollTo({
      top: 0
    });

    if (currentStep !== 9) {
      setLastScreen(currentStep)
    }
  }, [currentStep])

  
  return (
    <Router>
      <h1>DA Uzi<br /><br />Le chemin des braves</h1>
      <p>Le jeu concours est terminé, merci d'avoir participé</p>
      <Footer />
      {/* <div className="phone"><i>Speaker</i>
        <b>Camera</b> */}
          {/* <Fils /> */}
          {/* {currentStep === -0.6 && <NameScreen boucleProd={boucleProd} userName={userName} setUserName={setUserName} setCurrentStep={setCurrentStep} />}
          {currentStep === -0.5 && <TransitionScreen soundScreen={soundScreen} logoScreen={logoScreen} setCurrentStep={setCurrentStep} />}
          {(currentStep === 1 || currentStep === 3 || currentStep === 5)  && <Notification boucleProd={boucleProd} author={notificationAuthor} text={notificationText} notificationRef={notificationRef} />}
            {currentStep  === -2 && <Introduction introductionRef={introductionRef} /> }
          {currentStep === -1 && <StartingScreen boucleProd={boucleProd} setCurrentStep={setCurrentStep} />}
          {(currentStep === 0 ||
            currentStep === 2 ||
            currentStep === 4 ||
            currentStep === 6) && <Call boucleProd={boucleProd} setIsOpenFacetime={setIsOpenFacetime} currentStep={currentStep} videoFacetimeRef={videoFacetimeRef} setCurrentStep={setCurrentStep} />}
          {currentStep === 1 && <FindComissariat boucleProd={boucleProd} launchNotification={launchNotification} currentStep={currentStep} setCurrentStep={setCurrentStep} />}
          {currentStep === 3 && <FindIndic boucleProd={boucleProd} launchNotification={launchNotification} currentStep={currentStep} setCurrentStep={setCurrentStep} />}
          {currentStep === 5 && <CheckPlanques boucleProd={boucleProd} launchNotification={launchNotification} currentStep={currentStep} setCurrentStep={setCurrentStep} />}
          {(currentStep === 7) && <FacetimeCall soundScreen={soundScreen} setCurrentStep={setCurrentStep} isOpenFacetime={isOpenFacetime} videoFacetimeRef={videoFacetimeRef} />}
          {currentStep === 8 && <Win />}
          {currentStep === 9 && <Loose boucleProd={boucleProd} userName={userName} lastScreen={lastScreen} setCurrentStep={setCurrentStep} />}
      </div>
      {isMobile === false && <Footer />} */}
    </Router>
  );
}

export default App;
