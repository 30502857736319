import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { distance, booleanPointInPolygon, point, polygon } from '@turf/turf'
import './index.scss'
import { createGeoJSONCircle } from '../../tools';
import indicationsJSON from './indications'
import SilhouetteSVG from '../../assets/images/silhouetteSVG.png'
import CrossSVG from '../../assets/images/cross.svg'
import Footer from '../../components/Footer'
import './index.scss'
import Boussole from '../CheckPlanques/boussole';
import Indication from './indication';
import ReactHowler from 'react-howler'
import IndicationAudio from '../../assets/sound/GENERAL_CLIQUE_GENERAL_BOUTONS_TEMOINS_PLANQUES.mp3'
import { Howl } from 'howler';
import boucleProdAudio from '../../assets/sound/BOUCLE.mp3'
import boucleCity from '../../assets/sound/MAP_AMBIANCE_VILLE.mp3'
import CompteARebourd10SEC from '../../assets/sound/COMPTE_A_REBOURD_10SEC.mp3'

mapboxgl.accessToken = 'pk.eyJ1Ijoid2FybmVybXVzaWNmcmFuY2UiLCJhIjoiY2p0aWplZXd3MTRwZjQwbGhuNG12Mm0ybSJ9.LrneL3ZdUPgxBcBrg9FhQQ';

const FindIndic = ({ currentStep, audios, boucleProd, setCurrentStep, launchNotification }) => {
    const mapContainer = useRef(null);

    const map = useRef(null);
    const [lng, setLng] = useState(2.53);
    const [lat, setLat] = useState(48.94);
    const [zoom, setZoom] = useState(12);
    const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
    const bounds = [
        [2.25, 48.82],
        [2.70, 48.99]
    ]
    const [speakText, setSpeakText] = useState({
        text: '',
        isOpen: false,
        silhouette: '',
        name: '',
        id: ''
    })

    const [timeLeft, setTimeLeft] = useState(50);

    const resetIndications = () => {
        const _indications = indicationsJSON
        _indications.forEach((el) => el.active = true)
        setIndications(_indications)
    }

    useEffect(() => {
        if (timeLeft === 0) {
            resetIndications()
            setCurrentStep(9)
        }

      const timer = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(timer);
    }, [timeLeft]);

    useEffect(() => {
        if (speakText.text === "" && map.current) {
            map.current.removeImage('cat' + speakText.id);
        }
        if (speakText.isOpen) {
            boucleProd.current.IndicationAudio.play()
        }
    }, [speakText.text])

    const [indications, setIndications] = useState(indicationsJSON)

    useEffect(() => {
        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/warnermusicfrance/ckzxybn3v002w14qjfc40a6xa',
            center: [lng, lat],
            zoom: zoom,
            maxBounds: bounds,
            minZoom: 13
        });

        map.current.doubleClickZoom.disable();
        map.current.dragRotate.disable();
        map.current.touchZoomRotate.disableRotation();
    });

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });

    const addYellowLayer = (indication) => {
        map.current.loadImage(
            SilhouetteSVG,
            (error, image) => {
            if (error) throw error;
            
                // Add the image to the map style.
                map.current.addImage('cat' + indication.id, image);
                
                // Add a data source containing one point feature.
                map.current.addSource('point' + indication.id, {
                    'type': 'geojson',
                    'data': {
                        'type': 'FeatureCollection',
                        'features': [
                            {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Point',
                                'coordinates': indication.zone
                                }
                            }
                        ]
                    }
                });
                
                // Add a layer to use the image to represent the data.
                map.current.addLayer({
                    'id': 'points' + indication.id,
                    'type': 'symbol',
                    'source': 'point' + indication.id, // reference the data source
                    'layout': {
                        'icon-image': 'cat' + indication.id, // reference the image
                        'icon-size': 0.25,
                        "icon-allow-overlap" : true,
                        "text-allow-overlap": true
                    }
                });
            }
        );

            map.current.addSource(indication.id, createGeoJSONCircle([indication.zone[0], indication.zone[1]], 0.2));

            map.current.addLayer({
                "id": indication.id,
                "type": "fill",
                "source": indication.id,
                "layout": {},
                "paint": {
                    "fill-color": "yellow",
                    "fill-opacity": 0
                }
            });
    }

    useEffect(() => {
        map.current.on('load', () => {
            if (isMobile) {
                map.current.on('touchstart', (e) => {
                    checkObjectives(e)
                });
            } else {
                map.current.on('click', (e) => {
                    checkObjectives(e)
                }); 
            }
            
            addYellowLayer(indications[0])
        })

        return (() => {
            console.log("Set indications")
            setIndications(indicationsJSON)
        })
    }, [])

    useEffect(() => {
        setTimeout(() => {
            launchNotification('Maître Zadourian', "Il est parti à Saint Denis, va voir là-bas ! ")
        }, 500)

        window.digitalData={							
            settings:{						
                reportSuites:"wmg,wmgfr"					
            },						
            page:{						
                pageInfo:{					
                    pageName:"DA Uzi:Le chemin des braves Game and Contest:Landing:Indicator",				
                    server:"DA Uzi:Site",				
                    platform:"MIS Custom Page",				
                    devTeam:"Warner Music France"				 
                },					
                category:{					
                    primaryCategory:"DA Uzi:Landing Page",				
                    pageType:"Landing Page:Contest"				
                }					
            },						
            content:{						
                artist:"DA Uzi",					
                label:"Warner Music International",					
                sublabel:"Warner Music France"					
            }
        }
        
        document.dispatchEvent(new CustomEvent('SPAPageTriggered'))
    }, [])

    const checkObjectives = (e) => {
        const currentEl = indications.find(element => element.active === true)
        console.log(currentEl)
        

        var to = [currentEl.lat, currentEl.long]
        var from = [e.lngLat.wrap().lng, e.lngLat.wrap().lat]
        var options = { units: 'meters' };
        
        if (booleanPointInPolygon(
            point(from),
            polygon([map.current.getSource(currentEl.id)._data.features[0].geometry.coordinates[0]])
        )) {            
            if (currentEl.isIndic) {
                setCurrentStep(4)
            } else {
                addYellowLayer(indications[currentEl.nextIndex])
                
                setSpeakText({
                    text: indications[currentEl.nextIndex - 1].text,
                    silhouette: '',
                    isOpen: true,
                    name: indications[currentEl.nextIndex - 1].name,
                    id: indications[currentEl.nextIndex - 1].id
                })

                currentEl.active = false
                let newArr = [...indications]; // copying the old datas array
                currentEl.active = false; // replace e.target.value with whatever you want to change it to

                setIndications(newArr);
            }
        }
    }

    const resetState = () => {
        setSpeakText({
            text: '',
            silhouette: '',
            isOpen: false,
            name: '',
            id: speakText.id
        })
    }
 
    return (
        <div className="pageIndication">
            <ReactHowler
                src={boucleProdAudio}
                playing={true}
                loop={true}
                html5={true}
                volume={1}
            />
            <ReactHowler
                src={boucleCity}
                playing={true}
                loop={true}
                html5={true}
                volume={1}
            />
            <ReactHowler
                src={CompteARebourd10SEC}
                playing={timeLeft < 10 ? true : false}
                loop={true}
                html5={true}
                volume={1}
            />
        <p className="timeLeft">{("0" + Math.floor((timeLeft / 60))).slice(-2)}:{("0" + timeLeft%60).slice(-2)}</p>
            {speakText.isOpen === false && <Boussole map={map} mapLng={lng} mapLat={lat} toFindLat={indications.find(element => element.active === true).zone[1]} toFindLng={indications.find(element => element.active === true).zone[0]} />}
            {speakText.isOpen && <Indication speakText={speakText} resetState={resetState} />}
            <div ref={mapContainer} className="map-container" />
            <Footer />
        </div>
    );
}

export default FindIndic