import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { booleanPointInPolygon, point, polygon } from '@turf/turf'
import './index.scss'
import { createGeoJSONCircle } from '../../tools';
import indications from './indications'
import Enigme from '../Enigme';
import PlanqueIcon from '../../assets/images/houseIcon.png'
import Footer from '../../components/Footer'
import Fils from '../../components/Fils';
import Pression from '../../components/Pression';
import JEUPLANQUEOUVERT from '../../assets/sound/JEU_PLANQUE_OUVERTE.mp3'
import JEURATE from '../../assets/sound/JEU_RATE.mp3'
import JEUREUSSI from '../../assets/sound/JEU_REUSSI.mp3'
import ReactHowler from 'react-howler/lib/ReactHowler';
import boucleProdAudio from '../../assets/sound/BOUCLE.mp3'
import boucleProdAudioLowPass from '../../assets/sound/BOUCLE_LOW_PASS.mp3'
import boucleCity from '../../assets/sound/MAP_AMBIANCE_VILLE.mp3'
import useSound from 'use-sound';
 
mapboxgl.accessToken = 'pk.eyJ1Ijoid2FybmVybXVzaWNmcmFuY2UiLCJhIjoiY2p0aWplZXd3MTRwZjQwbGhuNG12Mm0ybSJ9.LrneL3ZdUPgxBcBrg9FhQQ';

const CheckPlanques = ({  setCurrentStep, launchNotification }) => {
    const mapContainer = useRef(null)

    const [playPlanqueOuverte] = useSound(JEUPLANQUEOUVERT);
    const [playJeuReussi] = useSound(JEUREUSSI);
    const [playJeuRate] = useSound(JEURATE);

    const map = useRef(null);
    const [lng, setLng] = useState(2.53);
    const [lat, setLat] = useState(48.94);
    const [zoom, setZoom] = useState(12);
    const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
    const bounds = [
        [2.35, 48.9],
        [2.4, 48.96]
    ]
    const [isOnEnigme, setIsOnEnigme] = useState(false)
    const [currentPlanque, setCurrentPlanque] = useState(null)
    const [planques, setPlanques] =  useState(indications)

    useEffect(() => {
        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/warnermusicfrance/ckzxybn3v002w14qjfc40a6xa',
            center: [lng, lat],
            zoom: zoom,
            maxBounds: bounds,
            minZoom: 12
        });

        map.current.doubleClickZoom.disable();
        map.current.dragRotate.disable();
        map.current.touchZoomRotate.disableRotation();
    });

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize

        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });

    const setPlanque = (index) => {
        map.current.loadImage(
            PlanqueIcon,
            (error, image) => {
            if (error) throw error;
            try {map.current.addImage('cat' + planques[index].id, image);
              } catch (e) {
                console.log('image with id "arrow-head" is already exists');
              }
      
                // Add the image to the map style.
                
                
                if (map.current.getSource('point' + planques[index].id)) {
                } else {

                    // Add a data source containing one point feature.
                    map.current.addSource('point' + planques[index].id, {
                        'type': 'geojson',
                        'data': {
                            'type': 'FeatureCollection',
                            'features': [
                                {
                                'type': 'Feature',
                                'geometry': {
                                    'type': 'Point',
                                    'coordinates': planques[index].zone
                                    }
                                }
                            ]
                        }
                    });
                    
                }
                // Add a layer to use the image to represent the data.
                map.current.addLayer({
                    'id': 'points' + planques[index].id,
                    'type': 'symbol',
                    'source': 'point' + planques[index].id, // reference the data source
                    'layout': {
                        'icon-image': 'cat' + planques[index].id, // reference the image
                        'icon-size': 0.25,
                        "icon-allow-overlap" : true,
                        "text-allow-overlap": true
                    }
                });
            }
        );


        map.current.addSource(planques[index].id, createGeoJSONCircle([planques[index].zone[0], planques[index].zone[1]], 0.3));

        map.current.addLayer({
            "id": planques[index].id,
            "type": "fill",
            "source": planques[index].id,
            "layout": {},
            "paint": {
                "fill-color": "yellow",
                "fill-opacity": 0.4
            }
        });
    }

    useEffect(() => {
        map.current.on('load', () => {
            if (isMobile) {
                map.current.on('touchstart', (e) => {
                    checkObjectives(e)
                });
            } else {
                map.current.on('click', (e) => {
                    checkObjectives(e)
                }); 
            }

            planques.forEach((planque, index) => {
                setPlanque(index)
            })
        })
    }, [])

    useEffect(() => {
        window.digitalData={							
            settings:{						
                reportSuites:"wmg,wmgfr"					
            },						
            page:{						
                pageInfo:{					
                    pageName:"DA Uzi:Le chemin des braves Game and Contest:Landing:Hideout",				
                    server:"DA Uzi:Site",				
                    platform:"MIS Custom Page",				
                    devTeam:"Warner Music France"				 
                },					
                category:{					
                    primaryCategory:"DA Uzi:Landing Page",				
                    pageType:"Landing Page:Contest"				
                }					
            },						
            content:{						
                artist:"DA Uzi",					
                label:"Warner Music International",					
                sublabel:"Warner Music France"					
            }						
        }		

        document.dispatchEvent(new CustomEvent('SPAPageTriggered'));	
    }, [])

    useEffect(() => {
        if (isOnEnigme) {
            
        } else {
            
        }
    }, [isOnEnigme])

    const checkObjectives = (e) => {
        var from = [e.lngLat.wrap().lng, e.lngLat.wrap().lat]
        
        indications.forEach((indication) => {
            if (
                booleanPointInPolygon(
                    point(from),
                    polygon([map.current.getSource(indication.id)._data.features[0].geometry.coordinates[0]])
                )
            ) {
                if (indications.find(element => element.id === indication.id).active === true) {
                    setCurrentPlanque(indication.id)
                    setIsOnEnigme(true)
                }
            }
        })
    }

    const launchNotifAfterEnigme = () => {
        if (planques.filter((planque) => planque.active === true).length === 2) {
            launchNotification('LIBANAIS', "C'est forcément la prochaine", 2000)
        } else if (planques.filter((planque) => planque.active === true).length === 3) {
            launchNotification('LIBANAIS', "C'est pas cette planque là", 2000)
        }
    }

    useEffect(() => {
        if (planques.filter((planque) => planque.active === true).length === 0) {
            setCurrentStep(6)
        }
    }, [planques])

    useEffect(() => {
        launchNotification('LIBANAIS', 'Trouve la bonne planque !', 2000)
    }, [])

    return (
        <div className="checkPlanques">
            <ReactHowler
                src={boucleProdAudio}
                playing={isOnEnigme ? false : true}
                loop={true}
                html5={true}
                volume={1}
            />
            <ReactHowler
                src={boucleProdAudioLowPass}
                playing={isOnEnigme ? true : false}
                loop={true}
                html5={true}
                volume={1}
            />
            <ReactHowler
                src={boucleCity}
                playing={isOnEnigme ? false : true}
                loop={true}
                html5={true}
                volume={1}
            />
            {isOnEnigme && planques[planques.findIndex((planque) => planque.id === currentPlanque)].type === "crochetage" && <Pression playPlanqueOuverte={playPlanqueOuverte} playJeuReussi={playJeuReussi} playJeuRate={playJeuRate} launchNotification={launchNotification} setCurrentStep={setCurrentStep} map={map} planques={planques} setPlanques={setPlanques} currentPlanque={currentPlanque} setCurrentPlanque={setCurrentPlanque} isOnEnigme={isOnEnigme} launchNotifAfterEnigme={launchNotifAfterEnigme} setIsOnEnigme={setIsOnEnigme} />}
            {isOnEnigme && planques[planques.findIndex((planque) => planque.id === currentPlanque)].type === "electricite" && <Fils playPlanqueOuverte={playPlanqueOuverte} playJeuReussi={playJeuReussi} playJeuRate={playJeuRate} launchNotification={launchNotification} setCurrentStep={setCurrentStep} map={map} planques={planques} setPlanques={setPlanques} currentPlanque={currentPlanque} setCurrentPlanque={setCurrentPlanque} isOnEnigme={isOnEnigme} launchNotifAfterEnigme={launchNotifAfterEnigme} setIsOnEnigme={setIsOnEnigme} />}
            {isOnEnigme && planques[planques.findIndex((planque) => planque.id === currentPlanque)].type === "fingerprint" && <Enigme playPlanqueOuverte={playPlanqueOuverte} playJeuReussi={playJeuReussi} playJeuRate={playJeuRate} launchNotification={launchNotification} setCurrentStep={setCurrentStep} map={map} planques={planques} setPlanques={setPlanques} currentPlanque={currentPlanque} setCurrentPlanque={setCurrentPlanque} isOnEnigme={isOnEnigme} launchNotifAfterEnigme={launchNotifAfterEnigme} setIsOnEnigme={setIsOnEnigme} />}
            <div ref={mapContainer} className="map-container" />
            <Footer />
        </div>
    );
}

export default CheckPlanques